<template>
  <span>
    <b-card-actions v-if="$permissionAbility(BACK_LINK_SHOW, permissions)" title="Filters" action-collapse>
      <div>
        <!-- search input -->
        <div>
            <b-row>

              <b-col
                  md="3"
                  lg="4"
                  xs="12"
                  class="mb-2 mb-md-0"
              >
               <b-form-input
                   v-on:keyup="onSearch"
                   v-model.lazy="searchTerm"
                   placeholder="Target URL"
                   type="text"
                   class="d-inline-block mr-sm-1"
               />
              </b-col>

              <b-col
                  md="6"
                  lg="2"
                  xs="12"
                  class="mb-2 mb-md-0"
              >
                <v-select
                    id="project"
                    v-model="filterSelectProjectId"
                    :options="filteredProjectOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Select Project"
                    @input="loadItems"
                />
              </b-col>

              <b-col md="6" lg="2" xs="12" class="mb-2 mb-md-0">
                 <v-select
                     id="seller"
                     v-model="filterSelectSellersId"
                     :options="filteredSellerOptions"
                     :reduce="(option) => option.id"
                     label="name"
                     placeholder="Select Seller"
                     @input="loadItems"
                 />
              </b-col>

              <b-col
                  md="6"
                  lg="2"
                  xs="12"
                  class="mb-2 mb-md-0"
              >
                <v-select
                    v-model="filterSelectStatusId"
                    :options="outreachInvoiceStatusConstants"
                    :reduce="(item) => item.value"
                    label="name"
                    placeholder="Select Status"
                    class="mb-1 custom-font"
                    @input="loadItems"
                />
              </b-col>

              <b-col
                  md="6"
                  lg="2"
                  xs="12"
                  class="mb-2 mb-md-0"
              >
                <flat-pickr
                    v-model="filterPublishedRangeDate"
                    class="form-control"
                    placeholder="Select Published Date Range"
                    :config="{ mode: 'range', defaultDate: [filterPublishStartDate, filterPublishEndDate]}"
                    @input="loadItems"
                />
              </b-col>

            </b-row>
          </div>
      </div>
    </b-card-actions>
    <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template>

          <b-button
              v-if="$permissionAbility(BACK_LINK_CREATE, permissions)"
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
          >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          v-if="$permissionAbility(BACK_LINK_SHOW, permissions)"
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :columns="columns"
          :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"

      >
        <template slot="table-row" slot-scope="props">

          <!-- Back Link -->
          <template v-if="props?.column?.field === 'backlink_url_format'">
              <div v-if="props?.row?.backlink_url">
                <div>
                  Backlink:
                  <a
                      :href="props?.row?.site_list?.website + '/' + props.row.backlink_url"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="font-weight-bold"
                  >
                  {{ formatWebsite(props?.row?.site_list?.website + '/' + props?.row?.backlink_url) }}
                </a>
                </div>

                <div>
                  Target Link: <a
                    :href="props.row.targeted_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-weight-bold"
                >
                  {{ formatWebsite(props?.row?.targeted_url) }}
                </a>
                </div>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- Targeted Link -->
            <template v-if="props?.column?.field === 'targeted_url_format'">
              <div v-if="props?.row?.targeted_url">
                <a
                    :href="props.row.targeted_url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-weight-bold"
                >
                  {{ formatWebsite(props?.row?.targeted_url) }}
                </a>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- Back link Type  -->
            <template v-if="props?.column?.field === 'backlinks_type_format'">
              <div v-if="props?.row?.backlinks_type">
                <b-badge :variant="getBacklinkTypeVariant(props?.row?.backlinks_type)">
                  {{ props?.row?.backlinks_type_text }}
                </b-badge>

              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- Website -->
            <template v-if="props?.column?.field === 'website_format'">
              <div v-if="props?.row?.site_list">
                <a
                    :href="props.row.site_list?.website"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-weight-bold"
                >
                  {{ formatWebsite(props?.row?.site_list?.website) }}
                </a>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- seller_format -->
            <template v-if="props?.column?.field === 'seller_format'">
              <div v-if="props?.row?.seller">
                {{ props?.row?.seller?.name }}
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <template v-if="props?.column?.field === 'project_format'">
              <div v-if="props?.row?.project">
                {{ props?.row?.project?.data?.title }}
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- Niche -->

            <template v-if="props?.column?.field === 'niche_format'">
              <div v-if="props?.row?.niche">
                {{ props?.row?.niche?.name }}
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- Niche Type Format -->
            <template v-if="props?.column?.field === 'approved_niche'">
              <div v-if="props?.row?.approveforNiches">
                {{ props?.row?.approveforNiches?.name }}
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

          <!-- cost_price_format -->
            <template v-if="props?.column?.field === 'cost_price_format'">
              <div v-if="props?.row">
                <b-badge variant="light-success">{{ props?.row?.currency?.data?.currency }} {{ props?.row?.cost_price }}</b-badge>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <!-- Status -->
            <template v-if="props?.column?.field === 'status_format'">

              <b-badge
                  v-if="props?.row?.outreach_invoice_id"
                  :variant="props?.row?.invoice?.data?.status === paymentDueConstants
                          ? 'light-warning'
                          : props?.row?.invoice?.data?.status === paymentPaidConstants
                          ? 'light-success'
                          : 'light-secondary'">
                {{ props?.row?.invoice?.data?.status_text }}
              </b-badge>
              <b-badge variant="light-secondary" v-else>
                Pending
              </b-badge>


            </template>

          <span v-if="props.column.field === 'action'">

          <template v-if="!props.row.outreach_invoice_id && $permissionAbility(BACK_LINK_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                />
              </span>
            </template>

          <template v-if="!props.row.outreach_invoice_id && $permissionAbility(BACK_LINK_DELETE, permissions)">
              <span @click="onDelete(props.row.id)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

            <template v-else>
              N/A
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
        id="modal-create-form"
        centered
        :title="
        modelType === 'editModel' ? 'Edit Back Link' : 'Create Back Link'
      "
        hide-footer
        @hidden="hiddenModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="createFormValidation">
        <b-form v-on:submit.prevent="validationForm">

          <b-row>
            <b-col md="12" lg="12" xs="12">
              <!-- Website -->
              <b-form-group
                  label="Website"
                  label-for="website"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="website"
                    vid="website"
                    rules="required"
                >
                  <v-select
                      id="website"
                      v-model="selectSiteListId"
                      :options="filteredSiteListOptions"
                      :reduce="(option) => option.id"
                      label="website"
                      placeholder="Choose Here"
                      @input="filteredApproved"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" xs="12">
              <!-- approved_for -->
              <b-form-group label="Approved For" label-for="approved_for" class="required-label">
                <validation-provider
                    #default="{ errors }"
                    name="approved for"
                    vid="approved_for"
                    class="required-label"
                    rules="required"
                >
                  <v-select
                      id="approved_for"
                      v-model="approvedForId"
                      :options="filteredApprovedNicheOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                      rules="required"
                      @input="selectBacklinksTypeId = []; costPriceInput = 0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- backlinks_type -->
              <b-form-group
                  label="Backlinks Type"
                  label-for="backlinks_type"
                  class="required-label">
              <validation-provider
                  #default="{ errors }"
                  name="backlinks type"
                  vid="backlinks_type"
                  rules="required"
              >
                <v-select
                    id="backlinks_type"
                    v-model="selectBacklinksTypeId"
                    :options="outreachBackLinkTypeConstants"
                    :reduce="(option) => option.value"
                    label="name"
                    placeholder="Choose Here"
                    @input="calculateCost"
                    :disabled="!approvedForId"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


            <b-col cols="12">
              <b-row>
                <b-col md="6" lg="6" xs="12">
                    <b-form-group label="Currency" label-for="cost_price">
                      <v-select
                          id="currency"
                          v-model="selectedCurrency"
                          :options="filteredCurrencyOptions"
                          :reduce="(option) => option.id"
                          label="name"
                          placeholder=""
                          disabled
                          :clearable="false"
                      />
                    </b-form-group>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                <!-- cost_price -->
                <b-form-group label="Price" label-for="cost_price">
                  <validation-provider
                      #default="{ errors }"
                      name="cost_price"
                      vid="cost_price"
                  >
                    <b-form-input
                        id="cost_price"
                        type="number"
                        v-model="costPriceInput"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Cost Price"
                        disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
            </b-col>

            <b-col md="6" lg="12" xs="12">
              <!-- backlink_url -->
                  <b-form-group label="Backlink Link" label-for="backlink_url" class="required-label">
                    <validation-provider
                        #default="{ errors }"
                        name="Backlink Link"
                        vid="backlink_url"
                        rules="required"
                    >
                    <b-row>
                      <b-col cols="6">
                         <v-select
                             id="website"
                             v-model="selectSiteListId"
                             :options="filteredSiteListOptions"
                             :reduce="(option) => option.id"
                             label="website"
                             placeholder=""
                             @input="filteredApproved"
                             disabled=""
                         />
                      </b-col>

                <b-col cols="6">

                      <b-form-input
                          id="backlink_url"
                          type="text"
                          v-model="backLinkUrlInput"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Path of url without domain"
                      />

                </b-col>
              </b-row>
                       <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
            </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- targeted_url -->
            <b-form-group label="Targeted Link" label-for="targeted_url" class="required-label">
              <validation-provider
                  #default="{ errors }"
                  name="Targeted Link"
                  vid="targeted_url"
                  rules="required|url"
              >
                <b-form-input
                    id="targeted_url"
                    type="text"
                    v-model="targetedUrlInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="The website must be a valid URL. (e.g https://www.example.com)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- Project -->
            <b-form-group
                label="Project"
                label-for="project"
                class="required-label"
            >
              <validation-provider
                  #default="{ errors }"
                  name="project"
                  vid="project"
                  rules="required"
              >
                <v-select
                    id="project"
                    v-model="selectProjectId"
                    :options="filteredProjectOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- published_date-->
            <b-form-group label="Published Date" label-for="published_date">
              <validation-provider
                  #default="{ errors }"
                  name="published_date"
                  vid="published_date"
              >
                <b-form-datepicker
                    id="published_date"
                    v-model="publishedDateInput"
                    class="form-control custom-font"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    today-button
                    close-button
                    reset-button
                    placeholder="Published Date"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

<!--          <template v-if="modelType === 'editModel'">-->
<!--            <b-col md="12" lg="12" xs="12">-->
<!--              &lt;!&ndash; Status &ndash;&gt;-->
<!--              <b-form-group-->
<!--                  label="Status"-->
<!--                  label-for="status"-->
<!--                  class="required-label"-->
<!--              >-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="status"-->
<!--                    vid="status"-->
<!--                    rules="required"-->
<!--                >-->
<!--                  <v-select-->
<!--                      id="status"-->
<!--                      v-model="selectStatusId"-->
<!--                      :options="filtererStatusOptions"-->
<!--                      :reduce="(option) => option.id"-->
<!--                      label="name"-->
<!--                      placeholder="Choose Here"-->
<!--                  />-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider>-->
<!--              </b-form-group>-->
<!--            </b-col>-->
<!--          </template>-->

            <b-col md="12" lg="12" xs="12">
              <!-- remarks -->
              <b-form-group label="Remarks" label-for="remarks">
                <validation-provider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                >
                  <b-form-textarea
                      id="remarks"
                      v-model="remarksInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="modal-create-invoice-form"
        centered
        :title="
        modelType == 'editModel' ? 'Edit Invoice' : 'Create Invoice'
      "
        hide-footer
        @hidden="hiddenInvoiceModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="createInvoiceFormValidation">
        <b-form v-on:submit.prevent="validationInvoiceForm">
          <b-row>

          <b-col md="6" lg="6" xs="12">
            <!-- invoice_date-->
            <b-form-group label="Invoice Date" label-for="invoice_date" class="required-label">
              <validation-provider
                  #default="{ errors }"
                  name="invoice_date"
                  vid="invoice_date"
                  rules="required"
              >
                <b-form-datepicker
                    id="invoice_date"
                    v-model="invoiceDateInput"
                    class="form-control custom-font"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    today-button
                    close-button
                    reset-button
                    placeholder="Invoice Date"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="6" xs="12">
            <!-- payment_method -->
            <b-form-group
                label="Payment Method"
                label-for="payment_method"
                class="required-label"
            >
              <validation-provider
                  #default="{ errors }"
                  name="payment_method"
                  vid="payment_method"
                  rules="required"
              >
                <v-select
                    id="payment_method"
                    v-model="selectPaymentId"
                    :options="filteredPaymentOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="6" xs="12">
            <!-- payment_date-->
            <b-form-group label="Payment Date" label-for="payment_date">
              <validation-provider
                  #default="{ errors }"
                  name="payment_date"
                  vid="payment_date"
              >
                <b-form-datepicker
                    id="payment_date"
                    v-model="paymentDateInput"
                    class="form-control custom-font"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    today-button
                    close-button
                    reset-button
                    placeholder="Payment Date"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="6" xs="12">
            <!-- approver_id -->
              <b-form-group
                  label="Approver"
                  label-for="approver_id"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Approver"
                    vid="approver_id"
                >
                  <v-select
                      id="approver_id"
                      v-model="selectApproverId"
                      :options="filteredApproverOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                  >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- payment_details -->
            <b-form-group label="Payment Details" label-for="payment_details" >
              <validation-provider
                  #default="{ errors }"
                  name="Payment Details"
                  vid="payment_details"
              >
                <b-form-textarea
                    id="payment_details"
                    v-model="paymentDetailsInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Payment Details"
                    rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" lg="12" xs="12">
            <!-- remarks -->
            <b-form-group label="Remarks" label-for="remarks" >
              <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
              >
              <b-form-textarea
                  id="remarks"
                  v-model="remarksInput"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Remarks"
                  rows="3"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          </b-row>
          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>


  </b-card>
  </span>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
  BFormDatepicker,
  BFormTextarea,

} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import flatPickr from 'vue-flatpickr-component'

import { t } from "@/@core/libs/i18n/utils";
import {
  BACK_LINK_ACCESS,
  BACK_LINK_CREATE,
  BACK_LINK_EDIT,
  BACK_LINK_DELETE,
  BACK_LINK_SHOW,
  BACK_LINK_REVIEW, OUTREACH_SELLER_EDIT
} from "@/helpers/permissionsConstant";
import UserSelect from "@/layouts/components/UserSelect.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {formatDateRange} from "@/helpers/helpers";
import { outreachBackLinkTypeConstants, guestPostConstants, linkInsertionConstants } from "@/helpers/constant/outreachBackLinkTypeConstant";
import { paymentPaidConstants, paymentUnpaidConstants, paymentDueConstants, outreachInvoiceStatusConstants } from "@/helpers/constant/outreachPaymentStatusConstant";

export default {
  name: "BackLinkView",
  components: {
    BCardActions, UserSelect,
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    flatPickr,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormDatepicker,
    BFormTextarea,

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // Permissions
      BACK_LINK_ACCESS,
      BACK_LINK_CREATE,
      BACK_LINK_EDIT,
      BACK_LINK_DELETE,
      BACK_LINK_SHOW,
      BACK_LINK_REVIEW,

      outreachInvoiceStatusConstants,
      outreachBackLinkTypeConstants,
      linkInsertionConstants,
      guestPostConstants,

      paymentDueConstants,
      paymentPaidConstants,

      selectedCurrency: "",

      filterSelectSellersId: "",
      filteredSellerOptions: [],
      selectedSiteList: [],

      approvedForId: "",
      selectApproverId: "",
      filteredApproverOptions: [],
      filteredApprovedNicheOptions: [],

      filterNicheOption: [],
      filterSelectNicheId: "",

      filterWebsiteOption: [],
      filterSelectWebsiteId: "",

      filterCreatedByOption: [],
      filterSelectCreatedById: "",

      filterApprovedByOption: [],
      filterSelectApprovedById: "",

      filterPublishStartDate: "",
      filterPublishEndDate: "",
      filterPublishedRangeDate: "",

      filteredCurrencyOptions: [],

      filterSelectTypeId: "",

      filterCost: '',
      filterSelectStatusId: "",


      filteredApprovedForOptions: [],

      modelType: "",
      name: "",

      outreachBackLinkId: "",

      selectSiteListId: "",
      filteredSiteListOptions: [],
      selectBacklinksTypeId: "",
      backLinkUrlInput: "",
      targetedUrlInput: "",
      selectProjectId: "",
      filterSelectProjectId: "",
      costPriceInput: 0,
      costPriceCurrencyInput: "",
      publishedDateInput: "",
      selectStatusId: "",

      filteredProjectOptions: [],

      invoiceDateInput: "",
      selectSellerId: "",
      selectPaymentId: "",
      filteredPaymentOptions: [
        { id: 0, name: "Not Set" },
        { id: 1, name: "Bank Transfer" },
        { id: 2, name: "PayPal" },
        { id: 3, name: "Credit Card" },
        { id: 4, name: "Bkash" },
        { id: 5, name: "Nagadh" },
        { id: 6, name: "Rocket" },
      ],
      paymentDateInput: "",
      selectInvoiceStatusId: "",
      filteredInvoiceStatusOptions: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Awaiting Finance Approval" },
        { id: 2, name: "Paid" },
        { id: 3, name: "Rejected" },
      ],
      paymentDetailsInput: "",
      remarksInput: "",
      backLinkId: "",

      //table
      pageLength: 10,
      columns: [
        {
          label: "Back Link",
          field: "backlink_url_format",
          sortable: false,
        },
        {
          label: "Post Type",
          field: "backlinks_type_format",
          sortable: false,
        },
        {
          label: "Cost",
          field: "cost_price_format",
          sortable: false,
        },
        {
          label: "Project",
          field: "project_format",
          sortable: false,
        },
        {
          label: "Website",
          field: "website_format",
          sortable: false,
        },
        {
          label: "Seller",
          field: "seller_format",
          sortable: false,
        },
        {
          label: "Status",
          field: "status_format",
          sortable: false,
        },
        {
          label: "Published Date",
          field: "published_date",
          formatFn: this.formatDate,
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      await this.loadItems();
      await this.getFilteredSiteListOptions();
      await this.getAllProjects();
      await this.getAllEmployee();
      await this.getAllNiches();
      await this.getAllSellers();
      await this.getAllApprovers();
      await this.getAllCurrencies();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    OUTREACH_SELLER_EDIT() {
      return OUTREACH_SELLER_EDIT
    },

    async getAllSellers() {
      try {
        const response = await this.$api.get("api/outreach/seller/all");

        this.filteredSellerOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },


    getBacklinkTypeVariant(type) {

      const statusObj = outreachBackLinkTypeConstants.find(
          (item) => item.value === type
      );
      return statusObj ? statusObj.variant : "light-secondary";
    },

    async getAllCurrencies() {
      try {
        const response = await this.$api.get("api/currencies/all");

        this.filteredCurrencyOptions = (response?.data?.data || []).map((item) => {

          let name = item.currency;
          return {
            name,
            id: item.id,
          };
        });
        
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllApprovers() {
      try {
        const response = await this.$api.get("api/users/active-all");
        this.filteredApproverOptions = (response?.data?.data || []).map(
            item => ({
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }),
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getActiveEmployees() {
      return await this.$api.get('api/users/active-all')
    },

    formatWebsite(website) {
      const maxLength = 15;
      if (website.length > maxLength) {
        return website.slice(0, maxLength) + "...";
      }
      return website;
    },

    getApprovedForName(id) {
      const option = this.filteredApprovedForOptions.find(option => option.id === id);
      return option ? option.name : 'NA';
    },

    showModal() {
      this.$bvModal.show("modal-create-form");
    },

    showInvoiceModal(id, seller) {
      this.backLinkId = id;
      this.selectSellerId = seller?.id;
      this.$bvModal.show("modal-create-invoice-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-create-form");
      this.resetModal();
    },

    hiddenInvoiceModal() {
      this.$bvModal.hide("modal-create-invoice-form");
      this.resetInvoiceModal();
    },

    resetModal() {
      this.modelType = "";
      this.modelInvoiceType = "";
      this.outreachBackLinkId = "";

      this.selectSiteListId = "";
      this.approvedForId = "";
      this.selectBacklinksTypeId = "";
      this.backLinkUrlInput = "";
      this.targetedUrlInput = "";
      this.selectProjectId = "";
      this.costPriceInput = "";
      this.costPriceCurrencyInput = "";
      this.publishedDateInput = "";
      this.selectStatusId = "";
      this.remarksInput = "";

    },

    resetInvoiceModal() {
      this.modelInvoiceType = "";
      this.outreachBackLinkId = "";

      this.invoiceDateInput = "";
      this.selectSellerId = "";
      this.selectPaymentId = "";
      this.paymentDateInput = "";
      this.selectInvoiceStatusId = "";
      this.paymentDetailsInput = "";
      this.backLinkId = "";

    },

    onShow(value) {
      this.modelType = "editModel";
      this.outreachBackLinkId = value.id;
      this.selectSiteListId = value.site_list_id;

      this.filteredApproved()

      this.approvedForId = value.outreach_approvefor_niche_id;
      this.selectBacklinksTypeId = value.backlinks_type;
      this.backLinkUrlInput = value.backlink_url;
      this.targetedUrlInput = value.targeted_url;
      this.selectProjectId = value.project_id;
      this.costPriceInput = value.cost_price;
      this.costPriceCurrencyInput = value.cost_price_currency;
      this.publishedDateInput = value.published_date;
      this.selectStatusId = value.status;
      this.selectStatusId = value.status;
      this.remarksInput = value.remarks;
      this.selectedCurrency = value.currency_id

      this.showModal();
    },

    onShowDetails(id) {
      this.$router.push({
        name: "outreach-invoice-details",
        params: { id },
      });
    },


    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async getAllProjects() {
      try {
        const response = await this.$api.get("api/projects/approve");

        this.filteredProjectOptions = (response?.data?.data || []).map((item) => {
          let name = item.lead.title;
          return {
            name,
            id: item.id,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllNiches() {
      try {
        const response = await this.$api.get("api/outreach/niche/all");

        this.filterNicheOption = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllEmployee() {
      try {
        const response = await this.$api.get("api/users/active-all");
        this.filterCreatedByOption = (response?.data?.data || []).map(
            item => ({
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }),
        );
        this.filterApprovedByOption = (response?.data?.data || []).map(
            item => ({
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }),
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    calculateCost()
    {
      this.costPriceInput = 0
      const selectedApprovedNiche = this.filteredApprovedNicheOptions.find(item => item.id === this.approvedForId)

      this.selectedCurrency = this.selectedSiteList?.currency

      if (this.selectBacklinksTypeId === guestPostConstants) {
        this.costPriceInput = selectedApprovedNiche.pivot?.guest_post_pricing
      } else {
        this.costPriceInput = selectedApprovedNiche.pivot?.link_insertion_pricing
      }
    },

    filteredApproved(){
      this.filteredApprovedNicheOptions = []
      const selectedSite = this.filteredSiteListOptions.find(
          (site) => site.id === this.selectSiteListId
      );

      this.selectedSiteList = selectedSite;
      this.filteredApprovedNicheOptions = selectedSite
          ? selectedSite.approveforNiches
          : [];
    },

    async getFilteredSiteListOptions() {
      try {
        const response = await this.$api.get("/api/outreach/site-list/all/approved");

        this.filteredSiteListOptions = (response.data.data || []).map((item) => {
          let website = item.website;
          return {
            id: item.id,
            approveforNiches: item.approveforNiches,
            website,
            currency: item?.currency_id,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/back-links/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Backlink Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getBackLinkItems(params) {

      const { startDate, endDate } = formatDateRange(this.filterPublishedRangeDate)

      return await this.$api.get("api/back-links?include=invoice,currency,project", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          filterTargetUrl: this.searchTerm,
          filterSelectSellersId: this.filterSelectSellersId,
          filterSelectStatusId: this.filterSelectStatusId,
          filterPublishStartDate: startDate,
          filterPublishEndDate: endDate,
          filterSelectProjectId: this.filterSelectProjectId
        },
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {

        this.isLoading = true

        const outreachNiche = await this.getBackLinkItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
        });

        const data = outreachNiche?.data?.data;
        const meta = outreachNiche?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

        this.isLoading = false

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.createFormValidation.validate().then(async (success) => {
        if (success) {

          console.log("success");

          try {
            this.$refs.createFormValidation.reset();
            if (this.modelType == "editModel") {
              this.isSubmitLoading = true;
              await this.$api.put(
                  `/api/back-links/${this.outreachBackLinkId}`,
                  {
                    site_list_id: this.selectSiteListId,
                    currency_id: this.selectedCurrency,
                    backlinks_type: this.selectBacklinksTypeId,
                    backlink_url: this.backLinkUrlInput,
                    targeted_url: this.targetedUrlInput,
                    project_id: this.selectProjectId,
                    cost_price: this.costPriceInput,
                    cost_price_currency: this.costPriceCurrencyInput,
                    published_date: this.publishedDateInput,
                    status: this.selectStatusId,
                    outreach_approvefor_niche_id: this.approvedForId,
                    remarks: this.remarksInput
                  }
              );
              this.isSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Backlink Successfully Updated",
                },
              });
            } else {
              this.isSubmitLoading = true;

              await this.$api.post("/api/back-links", {
                site_list_id: this.selectSiteListId,
                currency_id: this.selectedCurrency,
                outreach_approvefor_niche_id: this.approvedForId,
                backlinks_type: this.selectBacklinksTypeId,
                backlink_url: this.backLinkUrlInput,
                targeted_url: this.targetedUrlInput,
                project_id: this.selectProjectId,
                cost_price: this.costPriceInput,
                cost_price_currency: this.costPriceCurrencyInput,
                published_date: this.publishedDateInput,
                remarks: this.remarksInput,
              });
              this.isSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Backlink Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createFormValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    validationInvoiceForm: async function () {
      this.$refs.createInvoiceFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.createInvoiceFormValidation.reset();
            if (this.modelType !== "editModel") {
              const pendingStatus = this.filteredInvoiceStatusOptions.find(option => option.id === 0);

              this.isSubmitLoading = true;
              await this.$api.post("/api/outreach-invoices", {
                invoice_number: this.invoiceNumberInput,
                invoice_date: this.invoiceDateInput,
                seller_id: this.selectSellerId,
                payment_method: this.selectPaymentId,
                payment_date: this.paymentDateInput,
                payment_details: this.paymentDetailsInput,
                remarks: this.remarksInput,
                status: pendingStatus.id,
                backlink_id: this.backLinkId,
                approver_id: this.selectApproverId,
                outreach_approvefor_niche_id: this.approvedForId,
              });
              this.isSubmitLoading = false;
              this.hiddenInvoiceModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Outreach Invoice Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createInvoiceFormValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },


  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker';
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
  
  